/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Blog {
  constructor () {
    this.blog_id = uuidV4()
    this.titulo = ''
    this.texto = ''
    this.resumo = ''
    this.autor = ''
    this.dt_criacao = ''
    this.url_youtube = ''
    this.imagens = []
  }
}
