/* eslint-disable */
const state = {
  modal_topo: false,
  editandoText: false,
  modal_texto: false,
  modal_imagem: false,
  modal_video: false,
  banner_texto: [],
  banner_imagem: [],
  cor_texto: '#000000',
  cor_fundo: '#CCCCCC',
};

const getters = {
  pegaEditando: (state) => {
    return state.editandoText
  },
  pegaCorTexto: (state) => {
    return state.cor_texto
  },
  pegaModalTopo: (state) => {
    return state.modal_topo
  },
  pegaModalTexto: (state) => {
    return state.modal_texto
  },
  pegaModalImagem: (state) => {
    return state.modal_imagem
  },
  pegaBannerTexto: (state) => {
    return state.banner_texto
  },
  pegaBannerImagem: (state) => {
    return state.banner_imagem
  },
};

const actions = {
  setCorTexto({commit}, cor_texto) {
    commit('SET_COR_TEXTO', cor_texto)
  },
  setEditando({commit}, editandoText) {
    commit('SET_EDITANDO_TEXTO', editandoText)
  },
  setBnImagem({commit}, banner_img) {
    commit('SET_BANNER_IMAGEM', banner_img)
  },
  setTexto({commit}, banner_texto) {
    commit('SET_BANNER_TEXTO', banner_texto)
  },
  setModalTopo({commit}, modal_topo) {
    commit('SET_MODAL_TOPO', modal_topo)
  },
  setModalTexto({commit}, modal_texto) {
    commit('SET_MODAL_TEXTO', modal_texto)
  },
  setModalImagem({commit}, modal_imagem) {
    commit('SET_MODAL_IMAGEM', modal_imagem)
  },
};
const mutations = {
  SET_EDITANDO_TEXTO(state, editandoText) {
    state.editandoText = editandoText
  },
  SET_COR_TEXTO(state, cor_texto) {
    state.cor_texto = cor_texto
  },
  SET_BANNER_TEXTO(state, banner_texto) {
    state.banner_texto = banner_texto
  },
  SET_MODAL_TOPO(state, modal_topo) {
    state.modal_topo = modal_topo
  },
  SET_MODAL_TEXTO(state, modal_texto) {
    state.modal_texto = modal_texto
  },
  SET_MODAL_IMAGEM(state, modal_imagem) {
    state.modal_imagem = modal_imagem
  },
  SET_BANNER_IMAGEM(state, banner_imagem) {
    state.banner_imagem = banner_imagem
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
